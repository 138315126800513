import { useEffect, useState } from "react";
import ModalBase from "../../../components/modals/ModalBase";
import apiRequest from "../../../helpers/apiRequest";
import { Urls } from "../../../utils/urls";
import { TAMModal } from "../../materials/components/AddMaterialModal";
import { TExam, TSub, TUnit } from "./ExamsConfigs";

type TProps = {
  subject: TSub;
  onEdit: () => void;
};

const SubjectDetailsModal = ({
  isOpen,
  onClose,
  subject,
  onEdit,
}: TProps & TAMModal) => {
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState<TUnit[]>([]);

  const getAllUnits = async () => {
    const { data, error } = await apiRequest<TUnit[]>({
      method: "GET",
      url: `${Urls.subjects}/${subject._id}/units`,
    });
    if (error) {
      alert(error);
    } else if (data) {
      console.log(data);
      setLoading(false);
      setUnits(data);
    }
  };
  useEffect(() => {
    if (isOpen) {
      getAllUnits();
    }
  }, [isOpen]);
  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      title={subject.name}
      // primaryBtn={{ title: "Add Material", }}
      secondaryBtn={{ title: "Close", onClick: onClose }}
    >
      <div className="mt-3">
        <p className="text-gray-500 text-sm">Units</p>
        <div className="pl-2">
          {units?.map((unit) => (
            <div className="w-1/2">
              <p>- {unit.name}</p>
            </div>
          ))}
        </div>
        <p
          onClick={onEdit}
          className="mt-5 text-sm text-blue-500 underline cursor-pointer"
        >
          Edit Subject
        </p>
      </div>
    </ModalBase>
  );
};

export default SubjectDetailsModal;
