import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import PageLayout from "../../layouts/PageLayout";
import { routes } from "../../utils/routes";
import { useEffect, useState } from "react";
import { TClass } from "../../types/Types";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import Loading from "../../components/loading/Loading";
import ClassItemComp from "./components/ClassItem";
import GenereteLinkModal from "./components/GenereteLinkModal";

type TModals = {
  linkGenerate?: TClass;
};

const Classes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState<TModals>({});
  const [classes, setClasses] = useState<TClass[]>([]);
  const fetchClasses = async () => {
    setLoading(true);
    const { data, error } = await apiRequest<{ classes: TClass[] }>({
      method: "GET",
      url: Urls.all_classes
    });
    if (error) {
      alert(error);
    } else if (data?.classes) {
      console.log(data.classes);
      setClasses(data?.classes);
    }
    setLoading(false);
  };

  // const showOrHideMaterial = async (
  //   id: string,
  //   visible: boolean,
  //   index: number
  // ) => {
  //   const { data, error } = await apiRequest<{ material: TClass }>({
  //     method: "PUT",
  //     url: Urls.material_visibility,
  //     data: { id, visible }
  //   });
  //   if (error) {
  //     alert(error);
  //   } else if (data?.material) {
  //     updateMaterialStateByIndex(index, { visible });
  //   }
  // };
  const deleteMaterial = async (id: string) => {
    const confirm = window.confirm("Are you sure want to delete?");
    if (!confirm) return;
    const { data, error } = await apiRequest<{ message: string }>({
      method: "DELETE",
      url: `${Urls.one_material}/${id}`
    });
    if (error) {
      alert(error);
    } else if (data?.message) {
      setClasses((prev) => prev.filter((a) => a._id != id));
      //   alert(data.message);
    }
  };
  const updateMaterialStateByIndex = (index: number, material: {}) => {
    let tmpMaterials = [...classes];
    tmpMaterials[index] = { ...tmpMaterials[index], ...material };
    setClasses(tmpMaterials);
  };
  useEffect(() => {
    fetchClasses();
  }, []);
  if (loading) return <Loading />;
  return (
    <PageLayout
      label="Classes"
      leftComp={
        <Button title="Add" onClick={() => navigate(routes.add_classes)} />
      }

    >
      <div className="pb-14 flex flex-row flex-wrap gap-5">
        {classes.map((a) => (
          <ClassItemComp classItem={a} setModals={setModals} />
        ))}
      </div>

      {modals.linkGenerate && (
        <GenereteLinkModal
          onClose={() =>
            setModals((prev: any) => ({ ...prev, linkGenerate: undefined }))
          }
          classItem={modals.linkGenerate}
          isOpen={modals.linkGenerate != undefined}
        />
      )}
    </PageLayout>
  );
};

export default Classes;
