import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}
type Props = {
    placeholder?: string;
    value?: string;
    label?: string;
    onChange?: (option: any) => void;
    options: any[];
    optionLabelKey: string;
    containerClassName?: string;
    error?:string;
}
export default function CustomDropDown({ placeholder, value, label, options, onChange, optionLabelKey, containerClassName,error }: Props) {
    return (
        <div className={'w-full z-50' + containerClassName} >
            <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <div className="mt-1 w-full   ">
                <Menu as="div" className="relative inline-block text-left w-full">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            {value ?? placeholder}
                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute  right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1 w-full">
                                {options.map(opt => <Menu.Item>
                                    {({ active }) => (
                                        <p
                                            onClick={() => onChange && onChange(opt)}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            {opt[optionLabelKey]}
                                        </p>
                                    )}
                                </Menu.Item>)}


                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            {error&&<p className='text-sm text-red-500' >{error}</p>}
        </div>
    )
}
