import "./loading.css";
const Loading = () => {
  return (
    <div className=" flex h-full w-[calc(100vw-400px)] ">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
