import React, { createContext, useContext, useState, useEffect } from "react";
import { TExam, TSub } from "../pages/configurations/components/ExamsConfigs";
import apiRequest from "../helpers/apiRequest";
import { Urls } from "../utils/urls";
import { EDLevel } from "../pages/configurations/components/EducationLevels";

interface AppContextType {
  educationLevels: EDLevel[] | null;
  exams: TExam[] | null;
  uploading: number | null;

  setExams: (data: TExam[] | null) => void;
  setEducationLevels: (data: EDLevel[] | null) => void;
  setUploading: (data: number | null) => void;

  fetchExams: () => Promise<void | null>;
  fetchEducationLevels: () => Promise<void | null>;
}

const AppContext = createContext<AppContextType>({
  educationLevels: null,
  exams: null,
  uploading: null,
  setUploading: () => {},
  setExams: () => {},
  setEducationLevels: () => {},
  fetchExams: async () => null,
  fetchEducationLevels: async () => null,
});

export const useAppProvider = () => useContext(AppContext);

export const AppProvider: React.FC<any> = ({ children }) => {
  const [exams, setExams] = useState<TExam[] | null>(null);
  const [educationLevels, setEducationLevels] = useState<EDLevel[] | null>(
    null
  );
  const [uploading, setUploading] = useState<number | null>(0);
  const fetchExams = async () => {
    const { data, error } = await apiRequest<{ exams: TExam[] }>({
      method: "GET",
      url: Urls.all_exams,
    });
    if (error) {
      alert(error);
    } else if (data) {
      // console.log(data)
      // setLoading(false);
      setExams(data.exams);
    }
  };

  const fetchEducationLevels = async () => {
    const { data, error } = await apiRequest<{ educational_levels: EDLevel[] }>(
      {
        method: "GET",
        url: Urls.all_ed_levels,
      }
    );
    if (error) {
      alert(error);
    } else if (data) {
      // console.log(data)

      setEducationLevels(data?.educational_levels);
    }
  };

  return (
    <AppContext.Provider
      value={{
        exams,
        uploading,
        setUploading,
        setExams,
        fetchExams,
        educationLevels,
        setEducationLevels,
        fetchEducationLevels,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
