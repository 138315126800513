import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Login from "../pages/auth/Login";
import Home from "../pages/home/Home";
import Customers from "../pages/users/Customers";
import { routes } from "../utils/routes";
import Configurations from "../pages/configurations/Configurations";
import Materials from "../pages/materials/Materials";
import AddMaterial from "../pages/materials/AddMaterial";
import Classes from "../pages/classes/Classes";
import AddClass from "../pages/classes/AddClass";
import Zoom from "../pages/zoom/Zoom";
import Quizzes from "../pages/quizzes/Quizzes";
import AddQuiz from "../pages/quizzes/AddQuiz";
import AdsManager from "../pages/ads/AdsManager";
import AddBannerAd from "../pages/ads/AddBannerAd";
import CustomerDetails from "../pages/users/CustomerDetails";
import UpdateCustomer from "../pages/users/UpdateCustomer";
import QuizzDetails from "../pages/quizzes/QuizzDetails";
import PrivacyPolicy from "../pages/t&p/PrivacyPolicy";
import DeleteUserDataTutorial from "../pages/tutorials/DeleteUserTutorial";

const AppRoutes = () => {
  const router = createBrowserRouter([
    {
      path: routes.login,
      element: <Login />,
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: routes.home, element: <Home /> },

        // Customer routes
        { path: routes.customers, element: <Customers /> },
        { path: routes.customer_details, element: <CustomerDetails /> },
        { path: routes.update_customer_data, element: <UpdateCustomer /> },

        { path: routes.configurations, element: <Configurations /> },
        { path: routes.materials, element: <Materials /> },
        { path: routes.add_materials, element: <AddMaterial /> },
        { path: routes.classes, element: <Classes /> },
        { path: routes.add_classes, element: <AddClass /> },

        { path: routes.quizzes, element: <Quizzes /> },
        { path: `${routes.quizzes}/:quiz_id`, element: <QuizzDetails /> },
        { path: routes.add_quiz, element: <AddQuiz /> },

        { path: routes.ads, element: <AdsManager /> },
        { path: routes.add_banner_ads, element: <AddBannerAd /> },
      ],
    },
    { path: routes.meeting, element: <Zoom /> },
    { path: routes.privacy_policy, element: <PrivacyPolicy /> },
    { path: routes.terms_and_conditions, element: <PrivacyPolicy /> },

    {
      path: routes.delete_user_data_tutorial,
      element: <DeleteUserDataTutorial />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default AppRoutes;
