import React, { useRef } from "react";
import FileIcon from "../assets/svgs/FileIcon";
type Props = {
    files?: any;
    onChange?: (files: FileList | null) => void;
    label?: string;
    accept?: string;
};
const FileInput = ({ files, onChange, label, accept }: Props) => {
    const ref = useRef<HTMLInputElement>(null);
    console.log(files, 'fff')
    return (
        <div>
            <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <input
                ref={ref}
                className="hidden"
                accept={accept ?? "*/*"}
                type="file"
                onChange={(e) => onChange && onChange(e.target.files)}
            />
            <div className="mt-1 w-full h-20  flex items-center justify-center border border-dashed border-slate-600">
                {files?.length > 0 ? (
                    <div className='flex flex-col items-center justify-center' >
                        <FileIcon />
                        <p className='text-xs text-slate-500' >{files[0]?.name}</p>
                        <p className="text-xs text-red-500 underline cursor-pointer"
                            onClick={() => ref?.current?.click()}>Change</p>
                    </div>
                ) : (
                    <p
                        className="underline cursor-pointer"
                        onClick={() => ref?.current?.click()}
                    >
                        Browse
                    </p>
                )}
            </div>
        </div>
    );
};

export default FileInput;
