import React, { ChangeEvent } from 'react'
import TextInputComp from '../inputs/TextInputComp'
interface TProps{
onSearch?:(e:ChangeEvent<HTMLInputElement>)=>void
className?:string
}
const FilterBar = ({className,onSearch}:TProps) => {
  return (
    <div  className={`flex ${className}`} >
        <TextInputComp label='Search' containerclassName='w-[400px]' placeholder='Search for name' onChange={onSearch} />
    </div>
  )
}

export default FilterBar