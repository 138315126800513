import { FC } from "react";
import { TClass } from "../../../types/Types";
import moment from "moment";
import Button from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";

type TProps = {
  classItem: TClass;
  setModals: (items: any) => void;
};

const ClassItemComp: FC<TProps> = ({ classItem, setModals }) => {
  const navigate = useNavigate()
  return (
    <div className="p-3 w-2/5 border border-gray-300 rounded cursor-pointer hover:bg-slate-200">
      <div className="mb-2 flex justify-between ">
        <p className="font-bold w-[5/6]">{classItem.name}</p>
        <p className={classItem.isLive ? "text-green-900" : "text-red-900"}>
          {classItem.isLive ? "Live" : "Recorded"}
        </p>
      </div>
      <p className="text-sm text-gray-600">
        Start: {moment(classItem.start).format("MMMM Do YYYY, h:mm A")}
      </p>
      <p className="text-sm text-gray-600">
        End: {moment(classItem.end).format("MMMM Do YYYY, h:mm A")}
      </p>

      <div className='mt-3 flex items-center ' >
        {classItem.isLive && <p
          onClick={() =>
            setModals((prev: any) => ({ ...prev, linkGenerate: classItem }))
          }
          className="text-xs text-blue-500 underline cursor-pointer"
        >
          Get Link
        </p>}
        <div className='flex-1' />
        <p
          onClick={() => navigate(routes.add_classes, { state: { classItem, update: true } })}
          className="text-xs text-orange-500 underline cursor-pointer"
        >
          Edit
        </p>
      </div>
      {/* <Button title='Get Link' onClick={() => setModals((prev: any) => ({ ...prev, linkGenerate: classItem }))} /> */}
    </div>
  );
};

export default ClassItemComp;
