import React from "react";
import PageLayout from "../../layouts/PageLayout";

const AddBannerAd = () => {
  return (
    <PageLayout label="Add Banner">
      <div></div>
    </PageLayout>
  );
};

export default AddBannerAd;
