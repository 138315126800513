import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { TCustomer } from "../../types/Types";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import { useFormik } from "formik";
import TextInputComp from "../../components/inputs/TextInputComp";
import CustomDropDown from "../../components/dropDown/CustDropDown";
import { useAppProvider } from "../../providers/AppProvider";
import Button from "../../components/buttons/Button";

const UpdateCustomer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { educationLevels,exams } = useAppProvider();
  const { customer }: { customer: TCustomer } = location.state ?? {};

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    updateCustomer(values);
  };

  const updateCustomer = async (values: any) => {
    const { data, error } = await apiRequest<{ user: TCustomer }>({
      method: "POST",
      url: `${Urls.update_user}/${customer._id}`,
      data: values,
    });
    if (data) {
      alert("success");
      navigate(-1);
    } else if (error) {
      alert(error);
    }
  };
  const { values, handleSubmit, handleChange, setFieldValue, isSubmitting } =
    useFormik({
      initialValues: {
        ...(customer ?? {}),
      },
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting, resetForm }) =>
        onSubmit(values, { setSubmitting, resetForm }),
    });
  return (
    <PageLayout label="Update Details">
      <div className="flex items-center justify-center h-full">
        <div className="w-[400px]">
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <TextInputComp
              name="fullName"
              required
              value={values.fullName}
              onChange={handleChange}
              label="Full Name"
            />
            <TextInputComp
              name="phNo"
              required
              value={values.phNo}
              //   onChange={handleChange}
              disabled
            />
            <TextInputComp
              name="location"
              required
              value={values.location}
              onChange={handleChange}
              label="Location (Pin code)"
              type="number"
            />
            <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select Type"
              label={"Education"}
              value={values.education}
              //   options={[{ label: "Question Bank" }, { label: "Note" }]}
              options={
                educationLevels?.map((a) => ({
                  label: a.name,
                  value: a._id,
                })) ?? []
              }
              optionLabelKey="label"
              onChange={(option) => setFieldValue("education", option.label)}
            />
            {/* <Checkbox
              label="Visible to customers"
              onChange={(status) => setFieldValue("visible", status)}
              checked={values.visible}
            /> */}
            {/* <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select Exam"
              label={"Exam"}
              value={exams?.find((a) => a._id == values.exam)?.name}
              options={exams ?? []}
              optionLabelKey="name"
              onChange={(option) => setFieldValue("exam", option._id)}
            /> */}

            <Button
              title="Submit"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default UpdateCustomer;
