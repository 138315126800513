import { useFormik } from "formik";
import PageLayout from "../../layouts/PageLayout";
import TextInputComp from "../../components/inputs/TextInputComp";
import CustomDropDown from "../../components/dropDown/CustDropDown";
import Checkbox from "../../components/checkBox/CheckBox";
import Button from "../../components/buttons/Button";
import { useAppProvider } from "../../providers/AppProvider";
import Accordion from "../../components/accordian/Accordian";
import QnsAndAnswers from "./components/QnsAndAnswers";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import RichTextEditor from "../../components/inputs/richTextEditor/RichTextEditor";
import { TExam, TSub } from "../configurations/components/ExamsConfigs";
import { Await, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import moment from "moment";
import { TUnit } from "../../types/Types";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  exam: Yup.string()
    .required('Exam is required'),
  unit: Yup.string()
    .required('Unit is required'),
  subject: Yup.string()
    .required('Subject is required'),
  
});
export interface IQuestionAndAnswers {
  _id:string;
  question: string;
  answer: string;
  mcqs: string[];
  mark: number;
  negativeMark?: number;
  answerKey?: string;
}
export interface TQuiz {
  _id: string;
  name: string;
  start: string;
  end?: string;
  exam?: TExam;
  subject?: TSub;
  unit?:TUnit;
  visible?: boolean;
  isLive?: boolean;
  isFree: boolean;
  createdBy: string;
  numberOAttempts: number;
  description: string;
  elapsedTime?: number;
  questionAndAnswers: IQuestionAndAnswers[];
  syllabus: string;
}
type TLoadings = {
  units: boolean;
  page: boolean;
};

const AddQuiz = () => {
  const { exams } = useAppProvider();
  const [quiz, setQuiz] = useState<TQuiz>();
  const [loadings, setLoadings] = useState<TLoadings>({
    units: false,
    page: false,
  });
  const [units, setUnits] = useState<TUnit[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { isUpdate, quizId }: { isUpdate: boolean; quizId: string } =
    location?.state ?? {};

  const getOneQuiz = async () => {
    setLoadings((prev) => ({ ...prev, page: true }));
    const { data, error } = await apiRequest<{ quiz: TQuiz }>({
      method: "GET",
      url: `${Urls.quiz}/${quizId}`,

      // headers: { "Content-Type": "multipart/form-data" }
    });
    if (data) {
      setQuiz(data.quiz);
      if (data.quiz.subject?._id) {
        getSubjectUnits(data.quiz.subject?._id);
      }
    }
    if (error) {
      alert("something went wrong");
    }
    setLoadings((prev) => ({ ...prev, page: false }));
  };

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    if (isUpdate) {
      await updateHandler(values, resetForm);
    } else {
      await addHandler(values, resetForm);
    }
    navigate(-1);
    setSubmitting(false);
  };

  const addHandler = async (values: any, resetForm: any) => {
    const { data, error } = await apiRequest<{ quiz: TQuiz }>({
      method: "POST",
      url: Urls.quiz,
      data: JSON.stringify(values),
      // headers: { "Content-Type": "multipart/form-data" }
    });
    if (error) {
      alert(error);
    } else {
      // resetForm();
      alert("Success");
    }
  };
  const updateHandler = async (values: any, resetForm: any) => {
    const { data, error } = await apiRequest<{ quiz: TQuiz }>({
      method: "PATCH",
      url: `${Urls.quiz}/${quizId}`,
      data: JSON.stringify(values),
      // headers: { "Content-Type": "multipart/form-data" }
    });
    if (error) {
      alert(error);
    } else {
      // resetForm();
      alert("Success");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: quiz?.name ?? "",
      start: quiz?.start
        ? moment(quiz?.start).format("YYYY-MM-DDTHH:mm:ss")
        : "",
      end: quiz?.end ? moment(quiz?.end).format("YYYY-MM-DDTHH:mm:ss") : "",
      exam: quiz?.exam?._id ?? undefined,
      subject: quiz?.subject?._id ?? undefined,
      unit: quiz?.unit?._id ?? undefined,
      visible: quiz?.visible ?? true,
      isLive: quiz?.isLive ?? false,
      isFree: quiz?.isFree ?? false,
      elapsedTime: quiz?.elapsedTime ?? "",
      description: quiz?.description ?? "",
      questionAndAnswers: quiz?.questionAndAnswers ?? [],
      syllabus: quiz?.syllabus ?? "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) =>
      onSubmit(values, { setSubmitting, resetForm }),
    enableReinitialize: true,
  });
  const { values, handleSubmit, handleChange, setFieldValue, isSubmitting,errors } =
    formik;

  const getSubjectUnits = async (subId: string) => {
    setLoadings((prev) => ({ ...prev, units: true }));
    const { data, error } = await apiRequest<TUnit[]>({
      method: "GET",
      url: `${Urls.subjects}/${subId}/units`,
    });
    setLoadings((prev) => ({ ...prev, units: false }));
    if (data) {
      console.log(data, "dd");
      setUnits(data);
    }
    if (error) {
      alert(error);
    } else {
    }
  };

  useEffect(() => {
    if (isUpdate) {
      getOneQuiz();
    }
  }, [isUpdate]);

  if (loadings.page) return <Loading />;
  return (
    <PageLayout label="Add Quiz">
      <div className="pb-52">
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <TextInputComp
            id="name"
            name="name"
           error={errors.name as string}
            value={values.name}
            onChange={handleChange}
            label="Name"
            
          />

          <div className="flex items-center justify-start gap-10">
            <CustomDropDown
              containerClassName="mt-2 w-56"
              placeholder="Select Exam"
              label={"Exam"}
              value={exams?.find((a) => a._id == values.exam)?.name}
              options={exams ?? []}
              optionLabelKey="name"
              onChange={(option) => setFieldValue("exam", option._id)}
              error={errors.exam as string}
            />
            {values.exam && (
              <CustomDropDown
                containerClassName="mt-2 w-56"
                placeholder="Select Subject"
                label={"Subject"}
                value={
                  exams
                    ?.find((a) => a._id == values.exam)
                    ?.subjects?.find((a) => a._id == values.subject)?.name
                }
                options={
                  exams?.find((a) => a._id == values.exam)?.subjects ?? []
                }
                optionLabelKey="name"
                onChange={(option) => {
                  getSubjectUnits(option._id);
                  setFieldValue("subject", option._id);
                }}
                error={errors.subject as string}
              />
            )}
          </div>
          {values.subject && (
              <>
                {loadings.units ? (
                  <p>Loading Units...</p>
                ) : (
                  <CustomDropDown
                    containerClassName="mt-2"
                    placeholder="Select Unit"
                    label={"Unit"}
                    value={units.find((a) => a._id == values.unit)?.name}
                    options={units}
                    optionLabelKey="name"
                    onChange={(option) => {
                      setFieldValue("unit", option._id);
                    }}
                    error={errors.unit as string}
                  />
                )}
              </>
            )}
          <div className="flex items-center gap-10">
            <Checkbox
              label="Live"
              onChange={(status) => setFieldValue("isLive", status)}
              checked={values.isLive}
            />
            <Checkbox
              label="Visible to customers"
              onChange={(status) => setFieldValue("visible", status)}
              checked={values.visible}
            />
            <Checkbox
              label="Free"
              onChange={(status) => setFieldValue("isFree", status)}
              checked={values.isFree}
            />
          </div>
          <div className="flex items-center gap-10">
            {formik.values.isLive && (
              <>
                <TextInputComp
                  id="start"
                  name="start"
                  value={values.start}
                  onChange={handleChange}
                  label="Start time"
                  type="datetime-local"
                />
                <TextInputComp
                  id="end"
                  name="end"
                  value={values.end}
                  onChange={handleChange}
                  label="End time"
                  type="datetime-local"
                />
              </>
            )}
            <TextInputComp
              id="elapsedTime"
              name="elapsedTime"
              value={values.elapsedTime}
              onChange={handleChange}
              label="How long (in minutes)"
              type="number"
            />
          </div>
          <RichTextEditor
            className="mt-5"
            label="Description"
            value={formik.values.description}
            onChange={(value) => formik.setFieldValue("description", value)}
          />
          <RichTextEditor
            className="mt-5"
            label="Syllabus"
            value={formik.values.syllabus}
            onChange={(value) => formik.setFieldValue("syllabus", value)}
          />
          <QnsAndAnswers formik={formik} />

          <Button
            title="Submit"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </form>
      </div>
    </PageLayout>
  );
};

export default AddQuiz;
