import { useState } from "react"
import PageLayout from "../../layouts/PageLayout"
import ConfigTopBar, { TCBarItem } from "./components/ConfigTopBar"
import EducationLevels from "./components/EducationLevels"
import ExamsConfigs from "./components/ExamsConfigs"

const Configurations = () => {

  const [selTab, setSelTab] = useState<TCBarItem>('Education Levels')

  return (
    <PageLayout label='Configurations' >
      <div className="h-full">
        <ConfigTopBar selected={selTab} onClick={(a) => setSelTab(a)} />
        <div className="mt-5 h-full" >
          {selTab == 'Education Levels' && <EducationLevels />}
          {selTab == 'Exams' && <ExamsConfigs />}
        </div>
      </div>
    </PageLayout>
  )
}

export default Configurations