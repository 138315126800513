import React from "react";

const DeleteUserDataTutorial: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg text-center">
        <h1 className="text-2xl font-bold text-red-600 mb-4">
        Guidance Delete Your Account Permenantly
        </h1> 
        <p className="text-gray-700 mb-6">
          Once you delete your account, your data will be permanently erased and
          cannot be recovered. This action is non-refundable.
        </p>

        <div className="text-left mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Steps to Delete Your Account:
          </h2>
          <ol className="list-decimal ml-5 space-y-2 text-gray-700">
            <li>In Mobile app navigate to the Profile section.</li>
            <li>Click on the <span className="font-medium text-red-500">Delete User</span> option.</li>
            <li>Confirm the deletion by clicking on the confirmation prompt.</li>
          </ol>
        </div>

        <p className="text-gray-700 font-semibold mb-6">
          If you change your mind, you can always register again to join us!
        </p>

      </div>
    </div>
  );
};

export default DeleteUserDataTutorial;
