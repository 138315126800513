import React, { useState } from 'react';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  containerClassName?:string;
}

const Accordion2: React.FC<AccordionProps> = ({ title, children,containerClassName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={"border-b border-gray-200 "+containerClassName}>
      <button
        className="w-full text-left p-4 font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none"
        onClick={toggleAccordion}
      >
        {title}
        <span className="float-right">
          {isOpen ? '-' : '+'}
        </span>
      </button>
      {isOpen && <div className="p-4 bg-white">{children}</div>}
    </div>
  );
};

export default Accordion2;
