import { FieldArray, FormikProps, FormikProvider } from "formik";
import Accordion from "../../../components/accordian/Accordian";
import { useState } from "react";
import TextInputComp from "../../../components/inputs/TextInputComp";
import Button from "../../../components/buttons/Button";
import { IQuestionAndAnswers } from "../AddQuiz";
import CustomDropDown from "../../../components/dropDown/CustDropDown";
import convertStringToEditorState from "../../../helpers/convertStringToEditorState";
import RichTextEditor from "../../../components/inputs/richTextEditor/RichTextEditor";
import DownArrowIcon from "../../../assets/svgs/DownArrowIcon";
import DeleteIcon from "../../../assets/svgs/DeleteIcon";
import TrashIcon from '../../../assets/images/trash_icon.png'

type TProps = {
  formik: FormikProps<any>;
};
interface AccordionItemProps {
  index: number;
  formik: FormikProps<any>;
  remove:(index:any)=>void
}
const QnsAndAnswers = ({ formik ,}: TProps) => {
  return (
    <div className="mt-10">
      <p className="font-bold mb-2">Questions and Answers</p>
      <FormikProvider value={formik}>
        <FieldArray
          name="questionAndAnswers"
          render={({ push ,remove}) => (
            <div>
              {formik.values.questionAndAnswers?.map(
                (qnItem: IQuestionAndAnswers, index: number) => (
                  <AccordionItem key={index} index={index} formik={formik} remove={remove} />
                )
              )}
              <Button
                className="mt-5 bg-orange-400"
                title="Add Question"
                secondary
                onClick={() => push({ question: "" })}
              />
            </div>
          )}
        />
      </FormikProvider>
    </div>
  );
};

export default QnsAndAnswers;

const AccordionItem: React.FC<AccordionItemProps> = ({ index, formik ,remove}) => {
  const [isOpen, setIsOpen] = useState(false);
const deleteHandler=(index:number)=>{
const confirm =window.confirm('Are you sure want to delete tis question?')
if(confirm){
  remove(index)
}
}
  return (
    <div className="border-b">
      <div className="flex justify-between items-center w-full p-4 focus:outline-none cursor-pointer">
        <div className="flex gap-5 flex-1 ">
          <p className="font-semibold">{index + 1}.</p>
          <TextInputComp
            containerclassName="w-full"
            name={`questionAndAnswers[${index}].question`}
            required
            value={formik.values.questionAndAnswers[index].question}
            onChange={formik.handleChange}
            label="Question"
          />
        </div>
        <span
          onClick={() => setIsOpen(!isOpen)}
          className="ml-2 mt-5 text-xl font-bold"
          title="Show Details"
        >
          <DownArrowIcon  iconClassName={isOpen?"rotate-180":""} />
        </span>
        <div onClick={()=>deleteHandler(index)}  title='Delete' className="mt-5 ml-5" >
         <img src={TrashIcon} className="w-6 h-6" />
        </div>
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
          isOpen ? "" : "max-h-0"
        }`}
      >
        <div className="p-4">
          <div>
            <p>MCQs</p>
            <div className="flex gap-10">
              <TextInputComp
                id=""
                name={`questionAndAnswers[${index}].mcqs[0]`}
                required
                value={formik.values.questionAndAnswers[index].mcqs?.[0]}
                onChange={formik.handleChange}
                label=""
              />
              <TextInputComp
                id=""
                name={`questionAndAnswers[${index}].mcqs[1]`}
                required
                value={formik.values.questionAndAnswers[index].mcqs?.[1]}
                onChange={formik.handleChange}
                label=""
              />
              <TextInputComp
                id=""
                name={`questionAndAnswers[${index}].mcqs[2]`}
                required
                value={formik.values.questionAndAnswers[index]?.mcqs?.[2]}
                onChange={formik.handleChange}
                label=""
              />
              <TextInputComp
                id=""
                name={`questionAndAnswers[${index}].mcqs[3]`}
                required
                value={formik.values.questionAndAnswers[index]?.mcqs?.[3]}
                onChange={formik.handleChange}
                label=""
              />
            </div>
          </div>
          <div className="mt-3 flex gap-10">
            {/* <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select"
              label={"Answer"}
              value={formik.values.questionAndAnswers[index]?.answer}
              options={formik.values.questionAndAnswers[index]?.mcqs?.map((a:any)=>({name:a,value:a})) ?? []}
              optionLabelKey="name"
              onChange={(option) => formik.setFieldValue(`questionAndAnswers[${index}].answer`, option)}
            /> */}
            <TextInputComp
              name={`questionAndAnswers[${index}].answer`}
              required
              value={formik.values.questionAndAnswers[index].answer}
              onChange={formik.handleChange}
              label="Answer"
            />
            <TextInputComp
              name={`questionAndAnswers[${index}].mark`}
              required
              value={formik.values.questionAndAnswers[index].mark}
              onChange={formik.handleChange}
              label="Mark"
              type="number"
            />
            <TextInputComp
              name={`questionAndAnswers[${index}].negativeMark`}
              required
              value={formik.values.questionAndAnswers[index].negativeMark}
              onChange={formik.handleChange}
              label="Negetive Mark"
              type="number"
            />
          </div>
          <RichTextEditor
            className="mt-5"
            label="Answer Key"
            value={formik.values.questionAndAnswers[index].answerKey}
            onChange={(value) =>
              formik.setFieldValue(
                `questionAndAnswers[${index}].answerKey`,
                value
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
