import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosProgressEvent } from "axios";

interface ApiResponse<T> {
  data: T | null;
  error: string | null;

}

const BASE_URL = process.env.REACT_APP_API_URL;

async function apiRequest<T>(
  config: AxiosRequestConfig,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void // Updated type

): Promise<ApiResponse<T>> {
  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    onUploadProgress
  });

  // Add a response interceptor to handle 401 errors
  axiosInstance.interceptors.response.use(
    (response) => {
      // Any status code within the range of 2xx cause this function to trigger
      return response;
    },
    (error: AxiosError<any, any>) => {
      if (
        (error.response?.status === 401 &&
          error?.response?.data.message == "Unauthorized") ||
        error?.response?.data.message == "Invalid token"
      ) {
       window.location.href='/login'
      } else {
        return Promise.reject(error);
      }
    }
  );

  try {
    const response: AxiosResponse<T> = await axiosInstance.request<T>(config);
    // Successful response, return data
    return { data: response.data, error: null };
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      let errorMessage = "An error occurred.";
      if (axiosError.response) {
        // Server responded with a status code outside of 2xx
        const responseData = axiosError.response.data as ApiResponse<T>;
        errorMessage = responseData.error || errorMessage;
      } else if (axiosError.request) {
        // Request was made but no response received
        errorMessage = "No response received.";
      } else {
        // Something happened in setting up the request
        errorMessage = "Error setting up the request.";
      }
      return { data: null, error: errorMessage };
    } else {
      // Non-Axios error
      return { data: null, error: "An error occurred." };
    }
  }
}

export default apiRequest;
