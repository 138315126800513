import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import PageLayout from "../../layouts/PageLayout";
import { routes } from "../../utils/routes";
import { useEffect, useState } from "react";
import { TQuiz } from "./AddQuiz";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import Loading from "../../components/loading/Loading";
import moment from "moment";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import { TrashIcon } from "@heroicons/react/20/solid";
import EditIcon from "../../assets/svgs/EditIcon";

const Quizzes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [modals, setModals] = useState<TModals>({ add: true });
  const [quizzes, setQuizzes] = useState<TQuiz[]>([]);
  const fetchMaterials = async () => {
    setLoading(true);
    const { data, error } = await apiRequest<{ quizzes: TQuiz[] }>({
      method: "GET",
      url: Urls.get_all_quizzes,
    });
    if (error) {
      alert(error);
    } else if (data?.quizzes) {
      console.log(data.quizzes);
      setQuizzes(data?.quizzes);
    }
    setLoading(false);
  };

  const showOrHideMaterial = async (
    id: string,
    visible: boolean,
    index: number
  ) => {
    //   const { data, error } = await apiRequest<{ material: TQuiz }>({
    //     method: "PUT",
    //     url: Urls.material_visibility,
    //     data: { id, visible }
    //   });
    //   if (error) {
    //     alert(error);
    //   } else if (data?.material) {
    //     updateMaterialStateByIndex(index, { visible });
    //   }
  };
  
  const updateMaterialStateByIndex = (index: number, material: {}) => {
    //   let tmpMaterials = [...materials];
    //   tmpMaterials[index] = { ...tmpMaterials[index], ...material };
    //   setMaterials(tmpMaterials);
  };
  useEffect(() => {
    fetchMaterials();
  }, []);
  if (loading) return <Loading />;

  return (
    <PageLayout
      label="Quizzes"
      leftComp={
        <Button title="Add" onClick={() => navigate(routes.add_quiz)} />
      }
    >
      <div className="w-[calc(100vw-400px)]">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Start
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                End
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Elapsed Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Exam - Sub
              </th>
              {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th> */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Live
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {quizzes.map((quiz, index) => (
              <tr
                key={quiz._id}
                onClick={() => navigate(`${routes.quizzes}/${quiz._id}`)}
                className=" hover:bg-secondaryLight cursor-pointer"
              >
                <td className="px-6 py-4 whitespace-nowrap">{quiz.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {quiz.isLive && quiz.start
                    ? moment(quiz.start).format("DD - MMMM - YYYY,  h:mm:ss A")
                    : "-"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {quiz.isLive && quiz.end
                    ? moment(quiz.end).format("DD - MMMM - YYYY,  h:mm:ss A")
                    : "-"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {quiz.elapsedTime}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {quiz.exam?.name}-{quiz.subject?.name}
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap">{quiz.subject?.name}</td> */}
                <td className="px-6 py-4 whitespace-nowrap">
                  {quiz.isLive ? "Yes" : "No"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};

export default Quizzes;
