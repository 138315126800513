import { useEffect, useState } from "react";
import { TReport } from "../../../types/Types";
import apiRequest from "../../../helpers/apiRequest";
import { Urls } from "../../../utils/urls";
import Loading from "../../../components/loading/Loading";
import Accordion2 from "../../../components/accordian/Accordian2";
import NoItem from "../../../components/noItem/NoItem";

type TProps = {
  quizId: string;
};
const QuizReports = ({ quizId }: TProps) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<TReport[]>();

  const fetchReports = async () => {
    setLoading(true);
    const { data, error } = await apiRequest<{ reports: TReport[] }>({
      method: "GET",
      url: Urls.get_quiz_reports,
      params: { quizId },
    });
    if (error) {
      alert(error);
    } else if (data?.reports) {
      console.log(data.reports);
      setReports(data?.reports);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReports();
  }, [quizId]);
  if (loading) return <Loading />;
  return (
    <div className="mb-4">
      <h2 className="mb-3 text-xl font-bold">Reports</h2>
      {reports?.map((report, index) => (
        <Accordion2
          key={report._id}
          title={`${index + 1}.  ${report.title}`}
          containerClassName="mb-3"
        >
          <div>
          <p>Question: {report.qnNo}</p>
          <p className="mt-2 text-gray-500" >Description</p>
          <p>{report.description}</p>

          </div>
        </Accordion2>
      ))}

      {reports?.length==0&&<NoItem title='No Reports found for this quiz' />}
    </div>
  );
};

export default QuizReports;
