import React, { useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import AdsManagerTopBar from "./components/AdsManagerTopBar";
import AdsBannerSection from "./components/AdsBannerSection";

const AdsManager = () => {
  const [selected, setSelected] = useState<"Banner">("Banner");
  return (
    <PageLayout label="Ads Manager">
      <div>
        <AdsManagerTopBar selected={selected} onClick={(e) => setSelected(e)} />
        <div className="p-3">
          {selected == "Banner" && <AdsBannerSection />}
        </div>
      </div>
    </PageLayout>
  );
};

export default AdsManager;
