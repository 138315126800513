import React, { ReactNode } from "react";

const Header = ({ label,leftComp }: { label: string ,leftComp?:ReactNode}) => {
  return (
    <div className="p-2 text-xl flex items-center  font-bold bg-green-400">
      <p>{label}</p>
      <div className='flex-1' />
      {leftComp}
    </div>
  );
};

export default Header;
