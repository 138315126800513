import { useFormik, FormikHelpers, FieldArray, FormikProvider } from "formik";
import ModalBase from "../../../components/modals/ModalBase";
import { TAMModal } from "../../materials/components/AddMaterialModal";
import TextInputComp from "../../../components/inputs/TextInputComp";
import CustomDropDown from "../../../components/dropDown/CustDropDown";
import Checkbox from "../../../components/checkBox/CheckBox";
import Button from "../../../components/buttons/Button";
import { TrashIcon } from "@heroicons/react/20/solid";
import * as Yup from "yup";
import apiRequest from "../../../helpers/apiRequest";
import { TSub, TUnit } from "./ExamsConfigs";
import { Urls } from "../../../utils/urls";
import { useEffect, useState } from "react";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  units: Yup.array()
    // .of(Yup.string().required("Required"))
    .min(1, "At least one unit is required"),
});

type TProps = {
  examId: string;
  examName: string;
  selSubject?: TSub;
};

const AddSubjectModal = ({
  isOpen,
  onClose,
  examId,
  examName,
  selSubject,
}: TProps & TAMModal) => {
  const [units, setUnits] = useState<Omit<TUnit, "subject">[]>([
    { name: "", _id: "" },
  ]); // units fetched seperately for update as subjects not contain unit data here.
  const [loading, setLoading] = useState(false);
  const onSubmit = (values: any, actions: FormikHelpers<any>) => {
    if (selSubject) {
      updateHandler(values, actions);
    } else {
      addHandler(values, actions);
    }
  };
  const addHandler = async (values: any, actions: FormikHelpers<any>) => {
    const { data, error } = await apiRequest<{ subject: TSub }>({
      method: "POST",
      url: Urls.subjects,
      data: { ...values, examId },
    });
    if (error) {
      alert(error);
    } else {
      resetForm();
      onClose();
      alert("Success");
    }
  };
  const updateHandler = async (values: any, actions: FormikHelpers<any>) => {
    const { data, error } = await apiRequest<{ subject: TSub }>({
      method: "PATCH",
      url: `${Urls.subjects}/${selSubject?._id}`,
      data: { ...values, examId },
    });
    if (error) {
      alert(error);
    } else {
      resetForm();
      onClose();
      alert("Success");
    }
  };
  const formik = useFormik({
    initialValues: {
      name: selSubject?.name ?? "",
      visible: true,
      units: units,

      // ...material,
      // exam: material?.exam?._id ?? undefined,
      // subject: material?.subject?._id ?? undefined,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting,
    errors,
    submitForm,
    resetForm,
  } = formik;
  const onCancel = () => {
    const confirm = window.confirm(
      "Are you sure want to cancel? Your data wont be saved."
    );
    if (confirm) {
      resetForm();
      onClose();
    }
  };
  const getAllUnits = async () => {
    const { data, error } = await apiRequest<TUnit[]>({
      method: "GET",
      url: `${Urls.subjects}/${selSubject?._id}/units`,
    });
    if (error) {
      alert(error);
    } else if (data) {
      console.log(data);
      setLoading(false);
      setUnits(data);
    }
  };
  useEffect(() => {
    if (selSubject) {
      getAllUnits();
    }
  }, [selSubject]);
  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      title={"Add Subject to " + examName}
      primaryBtn={{
        title: isSubmitting ? "Loading..." : "Add",
        onClick: submitForm,
        disabled: isSubmitting,
      }}
      secondaryBtn={{ title: "Cancel", onClick: onCancel }}
    >
      <form onSubmit={handleSubmit} className="mt-5 flex flex-col gap-3 overflow-auto max-h-[80vh]">
        <TextInputComp
          id="name"
          name="name"
          required
          value={values.name}
          onChange={handleChange}
          label="Name"
          error={errors.name}
        />

        <p className="mt-5 text-lg">Units</p>
        {typeof errors.units == "string" && (
          <p className="text-sm text-red-500">Atleast one unit required</p>
        )}
        <FormikProvider value={formik}>
          <FieldArray
            name="units"
            render={({ push, remove }) => (
              <div>
                {values.units?.map((unit: any, index: number) => (
                  <div className="mb-2 flex items-center ">
                    <TextInputComp
                      id="index"
                      label={`Unit ${index + 1} name`}
                      name={`units[${index}].name`}
                      required
                      value={values.units[index]?.name}
                      onChange={handleChange}
                      containerclassName="mr-2 flex-1"
                      // errorMsg={errors.units?.[index]}
                    />
                    <TrashIcon
                      onClick={() => remove(index)}
                      title="delete"
                      fontSize={18}
                      width={24}
                      height={22}
                      className="cursor-pointer text-red-500"
                    />
                  </div>
                ))}
                <Button
                  className="mt-5 bg-orange-400"
                  title="Add Unit"
                  secondary
                  onClick={() => push("")}
                />
              </div>
            )}
          />
        </FormikProvider>
      </form>
    </ModalBase>
  );
};

export default AddSubjectModal;
