import React from "react";

const QuestionIcon = ({size=18}) => {
  return (
    <svg
    height={size}
    width={size}
      data-name="Слой 1"
      id="Слой_1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path d="M64,85a2,2,0,0,1-2-2c0-12,5.79-18.16,10.9-23.63,4.17-4.46,7.76-8.31,7.76-14.69a16.66,16.66,0,0,0-33.32,0,2,2,0,0,1-4,0,20.66,20.66,0,0,1,41.32,0c0,8-4.49,12.77-8.83,17.42C71,67.25,66,72.59,66,83A2,2,0,0,1,64,85Z" />
      <path d="M64,96.26a2.25,2.25,0,1,0,2.25,2.25A2.25,2.25,0,0,0,64,96.26Z" />
      <path d="M64,128a64,64,0,1,1,64-64A64.07,64.07,0,0,1,64,128ZM64,4a60,60,0,1,0,60,60A60.07,60.07,0,0,0,64,4Z" />
    </svg>
  );
};

export default QuestionIcon;
