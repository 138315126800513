export const Urls = {
  staff_login: "/staff/login",

  //users
  all_users: "/staff/getAllUsers",
  get_one_user: "/staff/user",
  update_user: "/staff/user",
  clear_login_data: "/staff/user/clearLoginDetails",
  disable_user: "/staff/user/disable",

  all_ed_levels: "/config/educationLevels",
  all_exams: "/config/exams",
  subjects: "/config/subject",

  all_materials: "/material/getAll",
  create_material: "/material/create",
  material_visibility: "material/visibility",
  one_material: "material",

  all_classes: "/staff/class/getAll",
  generate_video_presigned_url: "/staff/class/generate-video-uploadUrl",
  create_class: "/staff/class",
  get_meeting_jwt: "/staff/class/meeting",

  quiz: "/staff/quiz",
  get_all_quizzes: "/staff/quiz/getAll",
  get_quiz_reports: "/staff/quiz/report/getAll",
};
