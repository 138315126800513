import React from "react";

export interface TButton {
    onClick?: () => void;
    title: string;
    className?: string;
    loading?: boolean;
    disabled?: boolean;
    secondary?: boolean;
    type?: "button" | "submit" | "reset" | undefined
}

const Button: React.FC<TButton> = ({ onClick, title, className, loading, disabled, secondary, type }) => {
    return (
        <button
            onClick={onClick}
            className={`bg-blue-500 ${secondary ? 'bg-gray-600' : 'hover:bg-blue-700'} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${className}`}
            disabled={disabled}
            type={type ?? 'button'}
        >
            {loading ? "Loading..." : title}
        </button>
    );
};

export default Button;