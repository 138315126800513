import { useEffect, useState } from "react";
import { useUser } from "../../providers/UserProvider";
import { Urls } from "../../utils/urls";
import apiRequest from "../../helpers/apiRequest";
import { TCustomer } from "../../types/Types";
import Header from "../../components/header/Header";
import PageLayout from "../../layouts/PageLayout";
import moment from "moment";
import Pagination from "../../components/pagination/Pagination";
import FilterBar from "../../components/filterBar/FilterBar";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

const Customers = () => {
  const [customers, setCustomers] = useState<TCustomer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const fetchCustomers = async (pageNo = 1, search = "") => {
    const { data, error } = await apiRequest<{
      users: TCustomer[];
      totalPages: number;
      currentPage: number;
    }>({
      method: "GET",
      url: Urls.all_users,
      params: {
        limit: 10,
        pageNo,
        search,
      },
    });
    if (error) {
      alert(error);
    } else if (data?.users) {
      setCustomers(data?.users);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <PageLayout label="Customers">
      <div className="flex flex-col h-full pb-24">
        <FilterBar
          className="my-3"
          onSearch={(e) => fetchCustomers(1, e.target.value)}
        />
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Full Name
              </th>
              {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date of Birth
              </th> */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Education
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {customers.map((customer, index) => (
              <tr key={index} className={`${customer.disabled&&'bg-gray-200'}`} >
                <td className="px-6 py-4 whitespace-nowrap">
                  {customer.fullName}
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap">
                  {moment(customer.dob).format("DD - MMMM - YYYY")}
                </td> */}
                <td className="px-6 py-4 whitespace-nowrap">
                  {customer.education}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {customer.location}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.phNo}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.type}</td>
                <td
                  onClick={() =>
                    navigate(routes.customer_details, {
                      state: { phNo: customer.phNo },
                    })
                  }
                  className="px-6 py-4 whitespace-nowrap underline text-blue-500 cursor-pointer"
                >
                  Details
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex-1" />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={fetchCustomers}
        />
      </div>
    </PageLayout>
  );
};

export default Customers;
