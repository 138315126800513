import { useFormik } from "formik";
import PageLayout from "../../layouts/PageLayout";
import TextInputComp from "../../components/inputs/TextInputComp";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import { TMaterial, TUnit } from "../../types/Types";
import CustomDropDown from "../../components/dropDown/CustDropDown";
import { useAppProvider } from "../../providers/AppProvider";
import FileInput from "../../fileInput/FileInput";
import Checkbox from "../../components/checkBox/CheckBox";
import Button from "../../components/buttons/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import * as Yup from 'yup';

type TLoadings = {
  units: boolean;
};
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  exam: Yup.string()
    .required('Exam is required'),
  unit: Yup.string()
    .required('Unit is required'),
  subject: Yup.string()
    .required('Subject is required'),
});

const AddMaterial = () => {
  const [loadings, setLoadings] = useState<TLoadings>({ units: false });
  const [units, setUnits] = useState<TUnit[]>([]);
  const { exams,setUploading } = useAppProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const { material, update } = location?.state ?? {};

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    if (update) {
      await onUpdate(values, { setSubmitting, resetForm });
    } else {
      await onAdd(values, { setSubmitting, resetForm });
    }
    navigate(-1);
    setSubmitting(false);
  };

  const onAdd = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    let formData = new FormData();

    // Converting to formdata
    Object.keys(values).forEach((key) => {
      if (values[key]!=undefined) { // in order to filter out undefined values
        formData.append(key, values[key]);
      }
    });

    const { data, error } = await apiRequest<{ data: { material: TMaterial } }>(
      {
        method: "POST",
        url: Urls.create_material,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress(progressEvent) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
          );
          
          setUploading(progress);
        },
      }
    );

    if (error) {
      setUploading(null)
      alert(error);
    } else {
      setUploading(null)
      resetForm();
      alert("Success");
    }
  };
  const onUpdate = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    let formData = new FormData();
   // adding data to formdata
   Object.keys(values).forEach((key) => {
    if (values[key]!=undefined) { // filtering out undefined key value pairs
      formData.append(key, values[key]);
    }
  });
    const { data, error } = await apiRequest<{ data: { material: TMaterial } }>(
      {
        method: "PATCH",
        url: `${Urls.one_material}/${material._id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress(progressEvent) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
          );
          
          setUploading(progress);
        },
      }
    );

    if (error) {
      setUploading(null)
      alert("Error fetching units. Please try again");
    } else {
      setUploading(null)
      resetForm();
      alert("Success");
    }
  };

  const getSubjectUnits = async (subId: string) => {
    setLoadings((prev) => ({ ...prev, units: true }));
    const { data, error } = await apiRequest<TUnit[]>({
      method: "GET",
      url: `${Urls.subjects}/${subId}/units`,
    });
    setLoadings((prev) => ({ ...prev, units: false }));
    if (data) {
      console.log(data, "dd");
      setUnits(data);
    }
    if (error) {
      alert(error);
    } else {
    }
  };

  const { values, handleSubmit, handleChange, setFieldValue, isSubmitting,errors } =
    useFormik({
      initialValues: {
        name: "",
        type: undefined,

        file: null,
        visible: true,
        ...material,
        exam: material?.exam?._id ?? undefined,
        subject: material?.subject?._id ?? undefined,
        unit: material?.unit?._id ?? undefined,
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting, resetForm }) =>
        onSubmit(values, { setSubmitting, resetForm }),
    });

  useEffect(() => {
    if (material?.subject) {
      getSubjectUnits(material.subject._id);
    }
  }, [material]);

  return (
    <PageLayout label="Add Material">
      <div className="flex items-center justify-center h-full">
        <div className="w-[400px]">
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <TextInputComp
              id="name"
              name="name"
        
              value={values.name}
              onChange={handleChange}
              label="Name"
              error={errors.name as string}
            />
            <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select Type"
              label={"Type"}
              value={values.type}
              options={[{ label: "Question Bank" }, { label: "Note" }]}
              optionLabelKey="label"
              onChange={(option) => setFieldValue("type", option.label)}
            />
            {/* <Checkbox
              label="Visible to customers"
              onChange={(status) => setFieldValue("visible", status)}
              checked={values.visible}
            /> */}
            <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select Exam"
              label={"Exam"}
              value={exams?.find((a) => a._id == values.exam)?.name}
              options={exams ?? []}
              optionLabelKey="name"
              onChange={(option) => setFieldValue("exam", option._id)}
              error={errors.exam as string}
            />
            {values.exam && (
              <CustomDropDown
                containerClassName="mt-2"
                placeholder="Select Subject"
                label={"Subject"}
                value={
                  exams
                    ?.find((a) => a._id == values.exam)
                    ?.subjects?.find((a) => a._id == values.subject)?.name
                }
                options={
                  exams?.find((a) => a._id == values.exam)?.subjects ?? []
                }
                optionLabelKey="name"
                onChange={(option) => {
                  getSubjectUnits(option._id);
                  setFieldValue("subject", option._id);
                }}
                error={errors.subject as string}
              />
            )}
            {values.subject && (
              <>
                {loadings.units ? (
                  <p>Loading Units...</p>
                ) : (
                  <CustomDropDown
                    containerClassName="mt-2"
                    placeholder="Select Unit"
                    label={"Unit"}
                    value={units.find((a) => a._id == values.unit)?.name}
                    options={units}
                    optionLabelKey="name"
                    onChange={(option) => {
                      setFieldValue("unit", option._id);
                    }}
                    error={errors.unit as string}
                  />
                )}
              </>
            )}
            <FileInput
              label="Select File"
              files={values.file && [values.file]}
              onChange={(files) => setFieldValue("file", files?.[0])}
              accept="application/pdf"
            />
            <Button
              title="Submit"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default AddMaterial;
