import React, { useState } from "react";

interface CheckboxProps {
    label?: string;
    checked?: boolean;
    onChange?: (status: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.target.checked);
    };

    return (
        <div className='flex items-center gap-2' >
            <div className="mt-1 ">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
            </div>
            <label
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
