import React, { createContext, useContext, useState, useEffect } from 'react';
import { TUser } from '../types/Types';



interface UserContextType {
  userData: TUser | null;
  setUserData: (data: TUser | null) => void;
  fetchUser: () => Promise<TUser | null>;
  removeUser: () => void
}

const UserContext = createContext<UserContextType>({
  userData: null,
  setUserData: () => { },
  fetchUser: async () => null,
  removeUser: async () => null
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<any> = ({ children }) => {
  const [userData, setUserData] = useState<TUser | null>(null);

  const fetchUser = async () => {
    console.log('fetched user')
    let user = localStorage.getItem('userData')
    if (user) {
      setUserData(JSON.parse(user))
      return JSON.parse(user) as TUser
    }
    return null
  }
  const removeUser = async () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('token')
    setUserData(null)
  }

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData))

    }
  }, [userData]);


  return (
    <UserContext.Provider value={{ userData, setUserData, fetchUser, removeUser }}>
      {children}
    </UserContext.Provider>
  );
};