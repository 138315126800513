
type Props = {
    onClick: (label: TCBarItem) => void,
    selected: TCBarItem
}
export type TCBarItem = 'Education Levels' | 'Exams'



const ConfigTopBar = ({ selected, onClick }: Props) => {
    const Item = ({ label }: { label: TCBarItem }) => {
        return (
            <div className='cursor-pointer' onClick={() => { onClick(label) }} >
                <p className={`tex-primary underline ${selected == label && 'text-primary'} `} >{label}</p>
            </div>
        )
    }
    return (
        <div className="flex items-center gap-3" >
            <Item label={"Education Levels"} />
            <Item label="Exams" />
        </div>
    )
}

export default ConfigTopBar