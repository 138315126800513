import React from 'react';

interface DetailItemProps {
  label: string;
  value: React.ReactNode; // ReactNode to support various content types like strings, elements, etc.
}

const DetailItem: React.FC<DetailItemProps> = ({ label, value }) => {
  return (
    <div className="mb-4">
      <span className="font-semibold">{label}:</span> <span>{value}</span>
    </div>
  );
};

export default DetailItem;
