export const routes = {
  home: "/",
  login: "/login",

  customers: "/customers",
  customer_details: "/customer/details",
  update_customer_data: "/customer/update_data",

  configurations: "/configurations",
  materials: "/materials",
  add_materials: "/materials/add",
  classes: "/classes",
  add_classes: "/classes/add",
  meeting: "/meeting",

  quizzes: "/quizzes",
  add_quiz: "/quizzes/add",

  ads: "/ads",
  add_banner_ads: "/ads/add_banner",

  privacy_policy: "/privacy_policy",
  terms_and_conditions: "/terms_and_conditions",

  delete_user_data_tutorial: "/delete_user_data_tutorial",
};
