import React from 'react'
import { IQuestionAndAnswers, TQuiz } from '../AddQuiz'
import Accordion2 from '../../../components/accordian/Accordian2';
import DetailItem from './DetailItem';

type TProps={
    questionAndAnswers:IQuestionAndAnswers[];
}
const DetailsQnAndAnswers = ({questionAndAnswers}:TProps) => {
  return (
    <div className="mb-4">
          <h2 className="mb-3 text-xl font-bold">Questions and Answers</h2>
          {questionAndAnswers.map(
            (qa: IQuestionAndAnswers, index: number) => (
              <Accordion2
                key={qa._id}
                title={`Q ${index + 1}: ${qa.question}`}
                containerClassName="mb-3"

              >
                <DetailItem label="Answer" value={qa.answer} />
                <DetailItem
                  label="MCQs"
                  value={
                    <ul className="list-disc ml-6">
                      {qa.mcqs.map((mcq, i) => (
                        <li key={i}>{mcq}</li>
                      ))}
                    </ul>
                  }
                />
                <DetailItem label="Mark" value={qa.mark} />
                <DetailItem label="Negative Mark" value={qa.negativeMark} />
                <DetailItem
                  label="Answer Key"
                  value={
                    <div
                      className="prose"
                      dangerouslySetInnerHTML={{ __html: qa.answerKey ?? "" }}
                    />
                  }
                />
              </Accordion2>
            )
          )}
        </div>
  )
}

export default DetailsQnAndAnswers