import uitoolkit from "@zoom/videosdk-ui-toolkit";

import { useEffect, useState } from "react";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppProvider } from "../../providers/AppProvider";
import Button from "../../components/buttons/Button";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";

const Zoom = () => {
  const {} = useAppProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideTitle, setHideTitle] = useState(false);
  const [showingPreview, setShowingPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  let sessionContainer: any;
  let previewContainer: any;
  function joinSession() {
    setHideTitle(true);
    sessionContainer = document.getElementById("sessionContainer");
    let config = {
      videoSDKJWT: searchParams.get("jwt") ?? "",
      sessionName: searchParams.get("sessionName") ?? "",
      userName: searchParams.get("name") ?? "",
      // sessionPasscode: '123',
      //   features: ["video", "audio", "settings", "users", "chat", "share"],
      features: ["video", "audio", "users", "chat", "share",],
      
    };
    uitoolkit.joinSession(sessionContainer, config);
    uitoolkit.onSessionClosed(sessionClosed);
    setShowingPreview(false);
  }

  var sessionClosed = () => {
    setHideTitle(false);

    uitoolkit.closeSession(sessionContainer);
  };
  const showPreview = () => {
    setShowingPreview(true);
    previewContainer = document.getElementById("previewContainer");
    if (previewContainer) {
      uitoolkit.openPreview(previewContainer);
    }
  };
  useEffect(() => {
    if (!showingPreview) {
      showPreview();
    }
  }, [hideTitle]);
  return (
    <div className="">
      {!hideTitle && (
        <div className="p-10 h-screen flex flex-col justify-center items-center ">
          <h1 className="text-lg font-semibold text-green-500">Eduwise </h1>
          <p>Session: {searchParams.get("sessionName") ?? ""}</p>
          <div className="mt-10 w-[600px]">
            <div id="previewContainer"></div>
          </div>

          {/* <button onClick={joinSession}>Join Session</button> */}
          <Button
            className="mt-10 bg-primary"
            title="Join now"
            onClick={joinSession}
          />
        </div>
      )}

      <div className="flex items-center justify-center">
        <div className="w-[70%]">
          <div id="sessionContainer"></div>
        </div>
      </div>
    </div>
  );
};

export default Zoom;
