import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { TCustomer } from "../../types/Types";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import Loading from "../../components/loading/Loading";
import Button from "../../components/buttons/Button";
import EditIcon from "../../assets/svgs/EditIcon";
import { routes } from "../../utils/routes";

const CustomerDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<TCustomer>();
  const { phNo } = location.state ?? {};

  const fetchCustomer = async () => {
    const { data, error } = await apiRequest<{ userData: TCustomer }>({
      method: "GET",
      url: `${Urls.get_one_user}/${phNo}`,
    });
    if (data) {
      setCustomer(data.userData);
    } else if (error) {
      alert(error);
    }
  };
  const handleClearLoginData = async () => {
    const { data, error } = await apiRequest<{ userData: TCustomer }>({
      method: "GET",
      url: `${Urls.clear_login_data}/${customer?._id}`,
    });
    if (data) {
      alert("Cleared.");
    } else if (error) {
      alert(error);
    }
  };

  const handleEdit = () => {
    navigate(routes.update_customer_data, {
      state: {
        customer,
      },
    });
  };

  const handleDisable = async () => {
    const confirm = window.confirm("Are you sure want to disable this user?");
    if (!confirm) return;

    const { data, error } = await apiRequest<{ user: TCustomer }>({
      method: "POST",
      url: `${Urls.update_user}/${customer?._id}`,
      data: { disabled: !customer?.disabled },
    });
    if (data) {
      alert("success");
      fetchCustomer();
    } else if (error) {
      alert(error);
    }
  };
  useEffect(() => {
    fetchCustomer();
  }, []);

  if (!customer) return <Loading />;

  return (
    <PageLayout label={customer.fullName} >
      <div className="p-6 bg-white shadow-md rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">User Details</h2>
        </div>

        <div className="mb-4">
          <strong>Full Name:</strong> {customer.fullName}
        </div>
        <div className="mb-4">
          <strong>Phone Number:</strong> {customer.phNo}
        </div>
        <div className="mb-4">
          <strong>Education:</strong> {customer.education}
        </div>
        <div className="mb-4">
          <strong>Location:</strong> {customer.location}
        </div>
        <div className="mb-4">
          <strong>Subjects:</strong>{" "}
          {customer.subjects.map((a) => a.name).join(", ")}
        </div>
        <div className={`mb-4 ${customer.disabled && "text-red-500"}`}>
          <strong>Disabled:</strong> {`${customer.disabled}`}
        </div>
        <div className="mt-10 flex space-x-4">
          <Button title="Edit" className="" onClick={handleEdit} />
          <Button
            onClick={handleClearLoginData}
            title="Clear Device"
            secondary
          />
          <Button
            title={customer?.disabled ? "Enable" : "Disable"}
            onClick={handleDisable}
            className={!customer.disabled ? "bg-red-500" : "bg-green-500"}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default CustomerDetails;
