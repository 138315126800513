import React, { ReactNode } from 'react';
import Button, { TButton } from '../buttons/Button';

interface ModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    children?: ReactNode;
    primaryBtn?: TButton;
    secondaryBtn?: TButton;
}

const ModalBase: React.FC<ModalProps> = ({ title, isOpen, onClose, children, primaryBtn, secondaryBtn }) => {
    return (
        <div className={`fixed  inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}>
            <div className="relative bg-white w-full max-w-md mx-auto p-6 rounded shadow-lg">
                <div className="modal-content max-h-[100vh]">
                    <div className="modal-header flex justify-between items-center">
                        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
                        {/* <button className="modal-close" onClick={onClose} aria-label="Close modal">X</button> */}
                    </div>
                    <div className="">
                        {children}
                    </div>
                    <div className="modal-footer mt-4 flex justify-end gap-3">
                        {secondaryBtn
                            && <Button {...secondaryBtn} secondary />}
                        {primaryBtn
                            && <Button {...primaryBtn} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalBase;
