import React, { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useUser } from "../../providers/UserProvider";
import DashBoardIcon from "../../assets/svgs/DashBoardIcon";
import AccountsIcon from "../../assets/svgs/AccountsIcon";
import FileIcon from "../../assets/svgs/FileIcon";
import NotesIcon from "../../assets/svgs/NotesIcon";
import ClassIcon from "../../assets/svgs/ClassIcon";
import QuestionIcon from "../../assets/svgs/QuestionIcon";
import SettingsIcon from "../../assets/svgs/SettingsIcon";
import SpeakerIcon from "../../assets/svgs/SpeakerIcon";

const SideBar = () => {
  const navigate = useNavigate();
  const { removeUser } = useUser();

  const onLogoutPres = async () => {
    const confirm = window.confirm("Are you sure want to logout ?");
    if (!confirm) return;
    removeUser();
    navigate(routes.login);
  };

  const BtnItem = ({
    label,
    onClick,
    path,
    icon,
  }: {
    label: string;
    onClick: () => void;
    path?: string;
    icon: ReactNode;
  }) => {
    const { pathname } = useLocation();
    const selected =
      path == "/" ? pathname == "/" : pathname.includes(path ?? "");
    return (
      <div
        className={`mb-2  p-2 flex items-center gap-3 rounded cursor-pointer hover:bg-green-500 ${
          selected && "bg-green-600"
        }`}
        onClick={onClick}
      >
        {icon}
        <p>{label}</p>
      </div>
    );
  };

  return (
    <div className="w-full h-full p-3 border-r border-black flex flex-col bg-[#30dc78]">
      <div className="mb-10 m-3 ">
        <p className="text-3xl text-white font-bold">Eduwise</p>
      </div>
      <BtnItem
        icon={<DashBoardIcon />}
        path={routes.home}
        label="Dashboard"
        onClick={() => navigate(routes.home)}
      />
      <BtnItem
        icon={<AccountsIcon />}
        path={routes.customers}
        label="Customers"
        onClick={() => navigate(routes.customers)}
      />
      <BtnItem
        icon={<NotesIcon />}
        label="Materials"
        path={routes.materials}
        onClick={() => navigate(routes.materials)}
      />
      <BtnItem
        icon={<ClassIcon />}
        label="Classes"
        path={routes.classes}
        onClick={() => navigate(routes.classes)}
      />
      <BtnItem
        icon={<QuestionIcon />}
        label="Quizzes"
        onClick={() => navigate(routes.quizzes)}
        path={routes.quizzes}
      />
      <BtnItem
        icon={<SpeakerIcon />}
        label="Ads Manager"
        onClick={() => navigate(routes.ads)}
        path={routes.ads}
      />
      <BtnItem
        icon={<SettingsIcon />}
        label="Configurations"
        onClick={() => navigate(routes.configurations)}
        path={routes.configurations}
      />

      <div className="flex-1" />
      <p
        onClick={onLogoutPres}
        className="underline text-red-500 cursor-pointer"
      >
        Logout
      </p>
    </div>
  );
};

export default SideBar;
