import React from "react";
import ModalBase from "./ModalBase";
type TProps = {
  uploading: number | null;
  setUploading: (data: number | null) => void;
};
const UploadingModal = ({ uploading, setUploading }: TProps) => {
  return (
    <ModalBase
      isOpen={!!uploading}
      onClose={() => setUploading(null)}
      title="Uploading"
    //   primaryBtn={{
    //     title: "Close",
    //     disabled: uploading != 100,
    //     onClick: () => setUploading(null),
    //   }}
    >
      <div className="w-full max-w-md mx-auto mt-3">
        <div className="bg-gray-200 rounded-full h-6 ">
          <p
            className="bg-green-500 h-6 rounded-full text-xs font-medium text-white text-center  leading-none flex items-center justify-center"
            style={{ width: `${uploading ?? 0}%` }}
          >
            {uploading}%
          </p>
        </div>
      </div>
    </ModalBase>
  );
};

export default UploadingModal;
