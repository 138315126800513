import PageLayout from "../../layouts/PageLayout";

const Home = () => {
  return (
    <div>
      <PageLayout label="Dashboard">
        <div className="h-full w-full flex items-center justify-center">
          Under Development
        </div>
      </PageLayout>
    </div>
  );
};

export default Home;
