import React, { useEffect, useState } from "react";
import apiRequest from "../../../helpers/apiRequest";
import { Urls } from "../../../utils/urls";
import TextInputComp from "../../../components/inputs/TextInputComp";
import Button from "../../../components/buttons/Button";
import DeleteIcon from "../../../assets/svgs/DeleteIcon";
import Loading from "../../../components/loading/Loading";
import useApiRequest from "../../../hooks/useApiRequest";
import AddSubjectModal from "./AddSubjectModal";
import SubjectDetailsModal from "./SubjectDetailsModal";
import { useAppProvider } from "../../../providers/AppProvider";

export type TExam = {
  _id: string;
  name: string;
  subjects: TSub[];
};
export type TUnit = {
  name: string;
  _id: string;
  subject: string;
};
export type TSub = {
  name: string;
  _id: string;
  units?: TSub[];
};
type TLoadings = {
  addExam?: boolean;
  addSub?: boolean;
  deleteExam?: boolean;
  deleteSubject?: boolean;
};
type TModals = {
  addSubject: boolean;
  subjectDetails: undefined | TSub;
  updateSubject?: undefined | TSub;
};
const ExamsConfigs = () => {
  const { exams,setExams } = useAppProvider()!;

  // const [exams, setExams] = useState<TExam[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState<TLoadings>({});
  const [selExam, setSelExam] = useState<TExam | null>(exams?exams[0]:null)
  const [examName, setExamName] = useState<string>("");
  const [selSubject, setSelSubject] = useState<TSub>();
  const [modals, setModals] = useState<TModals>({
    addSubject: false,
    subjectDetails: undefined,
  });
  const getAllExams = async () => {
    const { data, error } = await apiRequest<{ exams: TExam[] }>({
      method: "GET",
      url: Urls.all_exams,
    });
    if (error) {
      alert(error);
    } else if (data) {
      console.log(data);
      setLoading(false);
      setExams(data.exams);
    }
  };
  const onAddExam = async () => {
    if (!examName) return alert("Fill column to continue");
    setLoadings((prev) => ({ ...prev, addExam: true }));
    const { data, error } = await apiRequest<any>({
      method: "POST",
      url: Urls.all_exams,
      data: { name: examName },
    });
    if (error) {
      alert(error);
    } else if (data) {
      setExamName("");
      getAllExams();
    }
    setLoadings((prev) => ({ ...prev, addExam: false }));
  };
  const onDeleteExam = async (id: string) => {
    const confirm = window.confirm("Are you sure want to delete ?");
    setLoadings((prev) => ({ ...prev, deleteExam: true }));
    if (!confirm) return;
    const { data, error } = await apiRequest<{ subject: TSub }>({
      method: "DELETE",
      url: `${Urls.all_exams}/${id}`,
    });
    if (error) {
      alert(error);
    } else if (data) {
      setSelExam(null);
      let examTmp  =exams?.filter((a) => a._id != id)??[]
      setExams(examTmp);
    }
    setLoadings((prev) => ({ ...prev, deleteExam: false }));
  };

  const onDeleteSubject = async (id: string) => {
    const confirm = window.confirm("Are you sure want to delete?");
    setLoadings((prev) => ({ ...prev, deleteSubject: true }));
    if (!confirm) return;
    const { data, error } = await apiRequest<{ subject: TSub }>({
      method: "DELETE",
      url: `${Urls.subjects}/${id}`,
    });
    if (error) {
      alert(error);
    } else if (data) {
      setSelExam(
        (prev) =>
          prev && {
            ...prev,
            subjects: [...prev.subjects.filter((a) => a._id != id)],
          }
      );
    }
    setLoadings((prev) => ({ ...prev, deleteSubject: false }));
  };
  useEffect(() => {
    getAllExams();
  }, []);
  if (loading) return <Loading />;

  return (
    <div className="h-full flex">
      {/* Side bar */}
      <div className="px-2 w-[240px] h-full border-r">
        <p className="mb-5 text-lg font-bold">Exams</p>
        {exams?.map((exam) => (
          <div
            key={exam?._id}
            onClick={() => setSelExam(exam)}
            className={`mb-2 px-2 py-1 flex items-center justify-between border bg-blue-300 rounded-lg cursor-pointer hover:bg-blue-200 ${
              exam?._id == selExam?._id && "bg-blue-500"
            }`}
          >
            <p className="">{exam.name}</p>
            <div
              className="cursor-pointer"
              title="Delete"
              onClick={(e) => onDeleteExam(exam?._id)}
            >
              {/* <DeleteIcon fill="red" size={20} /> */}
            </div>
          </div>
        ))}
        <div className=" mt-5 flex items-center gap-2">
          <TextInputComp
            placeholder="Exam Name"
            onChange={(e) => setExamName(e.target.value)}
            value={examName}
          />
          <Button onClick={onAddExam} title="Add" disabled={loadings.addExam} />
        </div>
      </div>
      {selExam && (
        <div className="px-3">
          <div className="flex items-center justify-between">
            <p className="text-lg font-bold">{selExam?.name}</p>
            <Button
              onClick={() =>
                setModals((prev) => ({ ...prev, addSubject: true }))
              }
              title="Add Subject"
              // loading={loadings.addSub}
              disabled={loadings.addSub}
            />
          </div>

          <div className="mt-3 flex gap-2 flex-wrap">
            {selExam?.subjects.map((sub) => (
              <div
                className={`w-1/3 p-2 flex gap-1 border border-slate-500 rounded cursor-pointer hover:bg-gray-300 v`}
                key={sub?._id}
                onClick={() =>
                  setModals((prev) => ({ ...prev, subjectDetails: sub }))
                }
              >
                <p className="mr-5 font-semibold">{sub?.name}</p>

                {/* <div className='cursor-pointer' title='Edit'  >
                        <EditIcon size={20} />
                    </div> */}
                <div
                  className="cursor-pointer"
                  title="Delete"
                  onClick={() => onDeleteSubject(sub?._id)}
                >
                  {/* <DeleteIcon fill="red" size={20} /> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!selExam && (
        <div className="p-3">
          <p className="text-black">
            Please select an exam to show its subjects
          </p>
        </div>
      )}

      <AddSubjectModal
        isOpen={modals.addSubject || modals.updateSubject != undefined}
        onClose={() => {
          setModals((prev) => ({
            ...prev,
            addSubject: false,
            updateSubject: undefined,
            subjectDetails: prev.updateSubject ?? undefined,
          }));
          getAllExams()
        }}
        examId={selExam?._id!}
        examName={selExam?.name!}
        selSubject={modals.updateSubject}
      />
      {modals.subjectDetails && (
        <SubjectDetailsModal
          isOpen={modals.subjectDetails != undefined}
          onClose={() =>
            setModals((prev) => ({ ...prev, subjectDetails: undefined }))
          }
          subject={modals.subjectDetails!}
          onEdit={() =>
            setModals((prev) => ({
              ...prev,
              updateSubject: prev.subjectDetails,
              subjectDetails: undefined,
            }))
          }
        />
      )}
    </div>
  );
};

export default ExamsConfigs;
