import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar/SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import { useUser } from "../providers/UserProvider";
import { routes } from "../utils/routes";
import { useAppProvider } from "../providers/AppProvider";
import UploadingModal from "../components/modals/UploadingModal";

const MainLayout = () => {
  const [initialising, setInitialising] = useState(true);

  const { fetchUser } = useUser();
  const { fetchExams, fetchEducationLevels, uploading, setUploading } =
    useAppProvider();
  const navigate = useNavigate();
  const init = async () => {
    let user = await fetchUser();

    setInitialising(false);
    if (!user) {
      navigate(routes.login, { replace: true });
    } else {
      await fetchExams();
      await fetchEducationLevels();
    }
  };
  useEffect(() => {
    init();
  }, []);

  if (initialising) return <div></div>;
  return (
    <div className="flex h-screen overflow-hidden relative">
      <div className="w-[255px] h-full">
        <SideBar />
      </div>
      <div className="flex-1">
        <Outlet />
      </div>
      <UploadingModal uploading={uploading} setUploading={setUploading} />
    </div>
  );
};

export default MainLayout;
