import moment from "moment";

const convertToMicroseconds = (isoDateString: string): string => {
    //    2024-06-28T07:51 input date string format

    const date = moment(isoDateString);

    const milliseconds = date.valueOf();

    const microseconds = milliseconds ;

    return microseconds.toString();
};
export default convertToMicroseconds