import React from "react";
import { TNativeInputProps } from "../../types/Types";

export type TInputFieldsProps = TNativeInputProps & {
  label?: string;
  containerclassName?: string;
  error?: string;
};
const TextInputComp: React.FC<TInputFieldsProps> = ({
  label,
  containerclassName,
  error: errorMsg,
  ...props
}) => {
  return (
    <div className={containerclassName} >
      <label
        htmlFor="password"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          {...props}
        />
      </div>
      <p className='text-xs text-red-500' > {errorMsg}</p>
    </div>
  );
};

export default TextInputComp;
