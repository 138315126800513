import { AppProvider } from "./providers/AppProvider";
import { UserProvider } from "./providers/UserProvider";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <div className="h-full w-full">
      <UserProvider>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </UserProvider>
    </div>
  );
}

export default App;
