import { useFormik } from "formik";
import apiRequest from "../../helpers/apiRequest";
import PageLayout from "../../layouts/PageLayout";
import { useAppProvider } from "../../providers/AppProvider";
import { TClass, TUnit } from "../../types/Types";
import { Urls } from "../../utils/urls";
import TextInputComp from "../../components/inputs/TextInputComp";
import CustomDropDown from "../../components/dropDown/CustDropDown";
import Checkbox from "../../components/checkBox/CheckBox";
import FileInput from "../../fileInput/FileInput";
import Button from "../../components/buttons/Button";
import convertToMicroseconds from "../../helpers/convertToMicroSeconds";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { InputFiles } from "typescript";
import axios from "axios";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  exam: Yup.string().required("Exam is required"),
  unit: Yup.string().required("Unit is required"),
  subject: Yup.string().required("Subject is required"),
});
type TLoadings = {
  units: boolean;
};
const AddClass = () => {
  const [loadings, setLoadings] = useState<TLoadings>({ units: false });
  const [units, setUnits] = useState<TUnit[]>([]);

  const { exams, setUploading } = useAppProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const { classItem, update } = location?.state ?? {};

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ) => {
    if (update) {
      await onUpdate(values, { setSubmitting, resetForm });
    } else {
      await onAdd(values, { setSubmitting, resetForm });
    }
    navigate(-1);
    setSubmitting(false);
  };

  const getPresignedUrl = async (fileName: string, fileType: string) => {
    const { data, error } = await apiRequest<{
      data: { url: string; key: string };
    }>({
      method: "GET",
      url: Urls.generate_video_presigned_url,
      params: { fileName, fileType },
    });
    return data?.data;
  };
  const uploadVideo = async (videoFile: File, presignedUrl: string) => {
    await axios.put(presignedUrl, videoFile, {
      headers: {
        "Content-Type": videoFile.type, // Set the content type of the file
      },
      onUploadProgress(progressEvent) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
        );

        setUploading(progress);
      },
    });
    // const { data, error } = await apiRequest<{
    //   data: { createdClass: TClass };
    // }>({
    //   method: "PUT",
    //   url: presignedUrl,
    //   data: videoFile,
    //   headers: { "Content-Type": videoFile.type },
    //   onUploadProgress(progressEvent) {
    //     const progress = Math.round(
    //       (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
    //     );

    //     setUploading(progress);
    //   },
    // });
  };

  const onAdd = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    try {
      let link;
      if (!values.isLive) {
        delete values.start;
        delete values.end;
        const videoData = await getPresignedUrl(
          values.file.name,
          values.file.type
        );
        await uploadVideo(values.file, videoData?.url!);
        link = videoData?.key;

        delete values.file;
      } else {
        delete values.file;
      }

      let formData = new FormData();
      // adding data to formdata
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          // filtering out undefined key value pairs
          formData.append(key, values[key]);
        }
      });
      if(link){
        formData.append('link',link)
      }
      formData.set("start", convertToMicroseconds(values.start));
      formData.set("end", convertToMicroseconds(values.end));
      const { data, error } = await apiRequest<{
        data: { createdClass: TClass };
      }>({
        method: "POST",
        url: Urls.create_class,
        data: formData,

        // onUploadProgress(progressEvent) {
        //   const progress = Math.round(
        //     (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
        //   );

        //   setUploading(progress);
        // },
      });
      if (error) {
        setUploading(null);
        alert(error);
      } else {
        resetForm();
        setUploading(null);
        alert("Success");
      }
    } catch (error: any) {
      alert(error.message ?? "Something went wrong");
    }
  };
  const onUpdate = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ): Promise<void> => {
    if (!values.isLive) {
      delete values.start;
      delete values.end;
    } else {
      delete values.file;
    }
    let formData = new FormData();

    // adding data to formdata
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        // filtering out undefined key value pairs
        formData.append(key, values[key]);
      }
    });
    formData.set("start", convertToMicroseconds(values.start));
    formData.set("end", convertToMicroseconds(values.end));
    const { data, error } = await apiRequest<{
      data: { createdClass: TClass };
    }>({
      method: "PATCH",
      url: `${Urls.create_class}/${classItem._id}`,
      data: formData,

      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress(progressEvent) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
        );

        setUploading(progress);
      },
    });
    if (error) {
      alert(error);
      setUploading(null);
    } else {
      resetForm();
      setUploading(null);
      alert("Success");
    }
  };
  // console.log(classItem, 'ccc')s
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting,
    errors,
  } = useFormik({
    initialValues: {
      name: classItem?.name ?? "",
      exam: classItem?.exam?._id,
      subject: classItem?.subject?._id,
      unit: classItem?.unit?._id,
      file: null,
      visible: classItem?.visible ?? true,
      start: classItem?.start
        ? moment(classItem?.start).format("YYYY-MM-DDTHH:mm")
        : undefined,
      end: classItem?.end
        ? moment(classItem?.end).format("YYYY-MM-DDTHH:mm")
        : undefined,
      isLive: classItem?.isLive ?? undefined,
      isFree: classItem?.isFree ?? false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) =>
      onSubmit(values, { setSubmitting, resetForm }),
  });
  const getSubjectUnits = async (subId: string) => {
    setLoadings((prev) => ({ ...prev, units: true }));
    const { data, error } = await apiRequest<TUnit[]>({
      method: "GET",
      url: `${Urls.subjects}/${subId}/units`,
    });
    setLoadings((prev) => ({ ...prev, units: false }));
    if (data) {
      console.log(data, "dd");
      setUnits(data);
    }
    if (error) {
      alert(error);
    } else {
    }
  };
  useEffect(() => {
    if (classItem?.subject) {
      getSubjectUnits(classItem?.subject?._id);
    }
  }, [classItem]);
  return (
    <PageLayout label={update ? "Edit Class" : "Add Class"}>
      <div className="flex items-center justify-center h-full">
        <div className="w-[400px]">
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <TextInputComp
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              label="Name"
              error={errors.name as string}
            />

            {/* <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select Type"
              label={"Type"}
              value={values.type}
              options={[{ label: "Question Bank" }, { label: "Note" }]}
              optionLabelKey="label"
              onChange={(option) => setFieldValue("type", option.label)}
            /> */}
            {/* <Checkbox
              label="Visible to customers"
              onChange={(status) => setFieldValue("visible", status)}
              checked={values.visible}
            /> */}

            <CustomDropDown
              containerClassName="mt-2"
              placeholder="Select Exam"
              label={"Exam"}
              value={exams?.find((a) => a._id == values.exam)?.name}
              options={exams ?? []}
              optionLabelKey="name"
              onChange={(option) => setFieldValue("exam", option._id)}
              error={errors.exam as string}
            />
            {values.exam && (
              <CustomDropDown
                containerClassName="mt-2"
                placeholder="Select Subject"
                label={"Subject"}
                value={
                  exams
                    ?.find((a) => a._id == values.exam)
                    ?.subjects?.find((a) => a._id == values.subject)?.name
                }
                options={
                  exams?.find((a) => a._id == values.exam)?.subjects ?? []
                }
                optionLabelKey="name"
                onChange={(option) => {
                  getSubjectUnits(option._id);
                  setFieldValue("subject", option._id);
                }}
                error={errors.subject as string}
              />
            )}
            {values.subject && (
              <>
                {loadings.units ? (
                  <p>Loading Units...</p>
                ) : (
                  <CustomDropDown
                    containerClassName="mt-2"
                    placeholder="Select Unit"
                    label={"Unit"}
                    value={units.find((a) => a._id == values.unit)?.name}
                    options={units}
                    optionLabelKey="name"
                    onChange={(option) => {
                      setFieldValue("unit", option._id);
                    }}
                    error={errors.unit as string}
                  />
                )}
              </>
            )}
            <div className="flex items-center gap-10">
              <Checkbox
                label="Live"
                onChange={(status) => setFieldValue("isLive", status)}
                checked={values.isLive}
              />
              <Checkbox
                label="Free"
                onChange={(status) => setFieldValue("isFree", status)}
                checked={values.isFree}
              />
            </div>
            {values.isLive && (
              <>
                <TextInputComp
                  id="start"
                  name="start"
                  required
                  value={values.start}
                  onChange={handleChange}
                  label="Start"
                  type="datetime-local"
                />
                <TextInputComp
                  id="end"
                  name="end"
                  required
                  value={values.end}
                  onChange={handleChange}
                  label="End"
                  type="datetime-local"
                />
              </>
            )}
            {!values.isLive && (
              <FileInput
                label="Select File"
                files={values.file && [values.file]}
                onChange={(files) => setFieldValue("file", files?.[0])}
                accept="video/*"
              />
            )}
            <Button
              title="Submit"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default AddClass;
