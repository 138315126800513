import React from "react";
import Button from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";

const AdsBannerSection = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold ">Banner Ads</h1>
        <Button
          title="Add Banner"
          onClick={() => navigate(routes.add_banner_ads)}
        />
      </div>
    </div>
  );
};

export default AdsBannerSection;
