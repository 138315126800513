import React, { useEffect, useState } from "react";
import apiRequest from "../../../helpers/apiRequest";
import { Urls } from "../../../utils/urls";
import Loading from "../../../components/loading/Loading";
import TextInputComp from "../../../components/inputs/TextInputComp";
import Button from "../../../components/buttons/Button";
import EditIcon from "../../../assets/svgs/EditIcon";
import DeleteIcon from "../../../assets/svgs/DeleteIcon";

export type EDLevel = {
    _id: string;
    name: string;
};
const EducationLevels = () => {
    const [edLevels, setEdLevels] = useState<EDLevel[]>([]);
    const [loading, setLoading] = useState(true);
    const [elName, setElName] = useState("");

    const getAllEls = async () => {
        const { data, error } = await apiRequest<{ educational_levels: EDLevel[] }>(
            {
                method: "GET",
                url: Urls.all_ed_levels
            }
        );
        if (error) {
            alert(error);
        } else if (data) {
            // console.log(data)
            setLoading(false);
            setEdLevels(data?.educational_levels);
        }
    };
    const onDeletePress = async (id: string) => {
        const confirm = window.confirm("Are you sure want to delete ?");
        if (!confirm) return;
        const { data, error } = await apiRequest<any>({
            method: "DELETE",
            url: `${Urls.all_ed_levels}/${id}`
        });
        if (error) {
            alert(error);
        } else if (data) {
            getAllEls();
        }
    };
    const onAddEducationalLevel = async () => {
        if (!elName) return alert("Fill column to continue");

        const { data, error } = await apiRequest<any>({
            method: "POST",
            url: Urls.all_ed_levels,
            data: { name: elName }
        });
        if (error) {
            alert(error);
        } else if (data) {
            getAllEls();
        }
    };
    const onEdit = async (name: string, id: string) => {
        const { data, error } = await apiRequest<any>({
            method: "GET",
            url: `${Urls.all_ed_levels}/${id}`,
            data: { name }
        });
        if (error) {
            alert(error);
        } else if (data) {
            getAllEls();
        }
    };

    useEffect(() => {
        getAllEls();
    }, []);
    if (loading) return <Loading />;
    return (
        <div>
            <div className="flex items-center gap-2">
                <TextInputComp
                    placeholder="Name"
                    onChange={(e) => setElName(e.target.value)}
                    value={elName}
                />
                <Button onClick={onAddEducationalLevel} title="Add" />
            </div>
            <div className="mt-3 flex gap-2">
                {edLevels.map((el) => (
                    <div className="p-2 flex gap-1 border border-slate-500 rounded">
                        <p className="mr-5">{el.name}</p>

                        {/* <div className='cursor-pointer' title='Edit'  >
                        <EditIcon size={20} />
                    </div> */}
                        <div
                            className="cursor-pointer"
                            title="Delete"
                            onClick={() => onDeletePress(el._id)}
                        >
                            <DeleteIcon fill="red" size={20} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EducationLevels;
