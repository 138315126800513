import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import apiRequest from "../../helpers/apiRequest";

import { Urls } from "../../utils/urls";
import { useNavigate, useParams } from "react-router-dom";
import Accordion2 from "../../components/accordian/Accordian2";
import { IQuestionAndAnswers, TQuiz } from "./AddQuiz";
import EditIcon from "../../assets/svgs/EditIcon";
import { TrashIcon } from "@heroicons/react/20/solid";
import { routes } from "../../utils/routes";
import DetailItem from "./components/DetailItem";
import DetailsQnAndAnswers from "./components/DetailsQnAndAnswers";
import QuizReports from "./components/Reports";
import DetailsTab from "./components/DetailsTab";
import Tabs from "../../components/tabs/Tabs";

const tabs = ["Questions", "Reports"];

const QuizDetails = () => {
  const [quiz, setQuiz] = useState<TQuiz | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const { quiz_id } = useParams();
  const navigate = useNavigate();

  const getQuizDetails = async () => {
    if (!quiz_id) return alert("Error, Invalid link");

    const { data, error } = await apiRequest<{ quiz: TQuiz }>({
      method: "GET",
      url: `${Urls.quiz}/${quiz_id}`,
    });
    if (error) {
      alert(error);
    } else if (data) {
      setQuiz(data.quiz);
      setLoading(false);
    }
  };
  const deleteQuiz = async (id: string) => {
    const confirm = window.confirm("Are you sure want to delete?");
    if (!confirm) return;
    const { data, error } = await apiRequest<{ message: string }>({
      method: "DELETE",
      url: `${Urls.quiz}/${id}`,
    });
    if (error) {
      alert(error);
    } else if (data?.message) {
      navigate(-1);
      //   alert(data.message);
    }
  };

  useEffect(() => {
    getQuizDetails();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (!quiz) {
    return <div className="text-center text-red-500">Quiz not found.</div>;
  }

  return (
    <PageLayout label="Quiz Details">
      <div className="p-6 bg-white shadow-lg rounded-lg">
        <div className="flex ">
          <h1 className="text-2xl font-bold mb-4">{quiz.name}</h1>
          <div className="flex-1" />
          <div className="flex items-center gap-2">
            <div
              title="Delete"
              className="cursor-pointer"
              onClick={() => deleteQuiz(quiz._id)}
            >
              <TrashIcon color="red" width={20} height={20} />
            </div>
            <div
              title="Edit"
              className="cursor-pointer"
              onClick={() =>
                navigate(routes.add_quiz, {
                  state: { isUpdate: true, quizId: quiz._id },
                })
              }
            >
              <EditIcon />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-x-10">
          <DetailItem label="Exam" value={quiz.exam?.name} />
          <DetailItem label="Subject" value={quiz.subject?.name} />
          <DetailItem label="Unit" value={quiz.unit?.name} />
          {quiz.isLive && (
            <>
              <DetailItem
                label="Start Time"
                value={new Date(quiz.start).toLocaleString()}
              />
              <DetailItem
                label="End Time"
                value={new Date(quiz.end ?? "").toLocaleString()}
              />
            </>
          )}
          <DetailItem label="Is Live" value={quiz.isLive ? "Yes" : "No"} />
          <DetailItem label="Is Free" value={quiz.isFree ? "Yes" : "No"} />
        </div>
        <DetailItem
          label="Description"
          value={
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: quiz.description }}
            />
          }
        />
        <DetailItem
          label="Syllabus"
          value={
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: quiz.syllabus }}
            />
          }
        />
      </div>

      <div className="mt-5 px-3 ">
        <Tabs tabs={tabs} onTabChange={(tab) => setSelectedTab(tab)} />
        <div className="mt-5" >
          {/* Question and answers */}
          {selectedTab == "Questions" && (
            <DetailsQnAndAnswers questionAndAnswers={quiz.questionAndAnswers} />
          )}
          {selectedTab == "Reports" && <QuizReports quizId={quiz._id} />}
        </div>
      </div>
    </PageLayout>
  );
};

export default QuizDetails;
