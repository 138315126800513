import React from "react";

const SpeakerIcon = ({ width = 18, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      id="레이어_1"
      version="1.1"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.7,4.6c-0.4-0.1-0.8,0.2-0.9,0.6c0,0.1-2.8,8.8-15.6,9.5H6c-2.2,0-4,2.1-4,4.7S3.8,24,6,24h0.7v8c0,1.9,1.1,3.4,2.5,3.4  h1.4c1.4,0,2.5-1.5,2.5-3.4v-7.9c12,1,14.6,9,14.7,9.4c0.1,0.4,0.4,0.6,0.8,0.6h0.1c0.4-0.1,0.7-0.4,0.7-0.8V5.4  C29.5,5,29.1,4.6,28.7,4.6z M3.7,19.3c0-1.6,1-3,2.3-3h5.4v6H6C4.8,22.3,3.7,21,3.7,19.3L3.7,19.3z M10.6,33.7H9.2  c-0.3,0-0.8-0.7-0.8-1.7v-8h3v8C11.4,33.1,10.9,33.7,10.6,33.7L10.6,33.7z M27.7,29.8c-2-2.9-6.3-6.7-14.6-7.4v-6.1  c8.3-0.7,12.6-4.5,14.6-7.4V29.8z" />
      <path d="M32.5,12.9c0.2,0,0.4-0.1,0.5-0.2l3.1-2.5c0.4-0.3,0.4-0.8,0.2-1.2c0,0,0,0,0,0c-0.3-0.4-0.8-0.4-1.2-0.1L32,11.3  c-0.4,0.3-0.4,0.8-0.1,1.2C32,12.7,32.3,12.9,32.5,12.9L32.5,12.9z" />
      <path d="M36,29.2l-3.1-1.2c-0.4-0.2-0.9-0.1-1.2,0.4s-0.1,0.9,0.4,1.2c0.1,0,0.1,0.1,0.2,0.1l3.1,1.2c0.1,0,0.2,0.1,0.3,0.1  c0.5,0,0.9-0.4,0.9-0.9C36.5,29.6,36.3,29.3,36,29.2z" />
      <path d="M37.1,19.2h-4.8c-0.5-0.1-0.9,0.3-0.9,0.8s0.3,0.9,0.8,0.9c0.1,0,0.1,0,0.2,0h4.8c0.5,0.1,0.9-0.3,0.9-0.8  c0.1-0.5-0.3-0.9-0.8-0.9C37.3,19.2,37.2,19.2,37.1,19.2z" />
    </svg>
  );
};

export default SpeakerIcon;
