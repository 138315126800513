import React, { useState } from "react";
import ModalBase from "../../../components/modals/ModalBase";
import { TClass } from "../../../types/Types";
import { useFormik } from "formik";
import { Urls } from "../../../utils/urls";
import apiRequest from "../../../helpers/apiRequest";
import TextInputComp from "../../../components/inputs/TextInputComp";
import CustomDropDown from "../../../components/dropDown/CustDropDown";
import Button from "../../../components/buttons/Button";
import * as Yup from 'yup';
type GLModal = {
    onClose: () => void;
    isOpen: boolean;
    classItem: TClass;
};
const roleOptions = [
    { value: "host", label: "Host" },
    { value: "user", label: "User" }
];
const validationSchema = Yup.object({
    name: Yup.string()
        .required('Name is required')
});
const GenereteLinkModal = ({ isOpen, onClose, classItem }: GLModal) => {
    const [jwt, setJwt] = useState("");
    const [link, setLink] = useState('')


    const setupLink = (jwt: string, name: string) => {
        const domain = `${window.location.protocol}//${window.location.host}`
        const sessionName = classItem.name;
        const sessionKey = classItem.sessionKey ?? ''

        setLink(`${domain}/meeting?sessionName=${sessionName}&name=${name}&sessionKey=${sessionKey}&jwt=${jwt}`)

    }
    const onSubmit = async (
        values: any,
        { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
    ): Promise<void> => {
        let formData = new FormData();
        Object.keys(values).forEach((key) => {
            formData.append(key, values[key]);
        });

        const { data, error } = await apiRequest<{ jwt: string }>({
            method: "GET",
            url: `${Urls.get_meeting_jwt}/${classItem._id}?role=${values.role}`,
            data: formData,

            headers: { "Content-Type": "multipart/form-data" }
        });
        if (error) {
            alert(error);
        } else {
            resetForm();
            if (data?.jwt) {
                setupLink(data?.jwt, values.name);
            }
            alert("Success");
        }
        setSubmitting(false);
    };
    const copyHandler = () => {
        navigator.clipboard.writeText(link ?? '')
            .then(() => {
                alert('Text copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        errors,
        submitForm
    } = useFormik({
        initialValues: {
            name: "",
            role: 'host'
        },
        validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) =>
            onSubmit(values, { setSubmitting, resetForm })
    });
    return (
        <ModalBase
            isOpen={isOpen}
            onClose={onClose}
            title="Generate link"
            primaryBtn={!link ? { title: "Generate", onClick: () => submitForm() } : undefined}
            secondaryBtn={{ title: "Cancel", onClick: onClose }}
        >
            <div>
                {link ? (
                    <div className='  ' >
                        {/* <p className='overflow-ellipsis whitespace-nowrap' style={{ width: 100 }} >{link}</p> */}
                        <p className='text-green-900 text-lg text-center' >Link Generated</p>
                        <p className='text-blue-500 mt-3 cursor-pointer text-center font-bold' onClick={copyHandler} >Copy</p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                        <TextInputComp
                            id="name"
                            name="name"
                            required
                            value={values.name}
                            onChange={handleChange}
                            label="Name"
                            error={errors.name}

                        />

                        <CustomDropDown
                            containerClassName="mt-2"
                            placeholder="Select role"
                            label={"Role"}
                            value={roleOptions?.find((a) => a.value == values.role)?.label}
                            options={roleOptions}
                            optionLabelKey="label"
                            onChange={(option) => setFieldValue("role", option.value)}
                        />
                    </form>
                )}
            </div>
        </ModalBase>
    );
};

export default GenereteLinkModal;
