import React, { useState } from "react";
import TextInputComp from "../../components/inputs/TextInputComp";
import { useFormik } from "formik";
import apiRequest from "../../helpers/apiRequest";
import { TUser } from "../../types/Types";
import { Urls } from "../../utils/urls";
import { useUser } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import Button from "../../components/buttons/Button";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setUserData } = useUser();

  const onSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    const { data, error } = await apiRequest<{
      data: { staff: TUser; token: string };
    }>({
      method: "POST",
      url: Urls.staff_login,
      data: { ...values }
    });

    if (error) {
      alert(error);
    } else {
      console.log(data?.data.staff);
      setUserData(data!.data.staff);
      localStorage.setItem("token", data!.data.token);
      setTimeout(() => {
        navigate(routes.home);
      }, 2000);
    }
    // setSubmitting(false);
  };

  const { values, handleSubmit, handleChange, isSubmitting } = useFormik({
    initialValues: {
      password: "",
      email: ""
    },

    onSubmit: (values, { setSubmitting }) => onSubmit(values, { setSubmitting })
  });

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <TextInputComp
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={values.email}
              onChange={handleChange}
              label="Email"
            />

            <TextInputComp
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              label={"Password"}
              value={values.password}
              onChange={handleChange}
            />

            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                loading={isSubmitting}
                title="Sign In"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
