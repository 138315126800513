import React from "react";

const NotesIcon = ({ size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1">
        <path d="M1,49h38.414L49,39.414V1H1V49z M40,45.586V40h5.586L40,45.586z M3,3h44v35h-9v9H3V3z" />
        <rect height="2" width="30" x="10" y="13" />
        <rect height="2" width="30" x="10" y="20" />
        <rect height="2" width="30" x="10" y="27" />
      </g>
      <g />
    </svg>
  );
};

export default NotesIcon;
