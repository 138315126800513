import { useQuill } from "react-quilljs";
import { useEffect } from "react";
import 'quill/dist/quill.snow.css'; // Add css for snow theme

type TProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
};

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  [ "image",  "formula"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];
const RichTextEditor = ({ value, onChange, label, className }: TProps) => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: toolbarOptions,
    },
  });

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(value??'');
      quill.on("text-change", (delta, oldDelta, source) => {
        console.log("Text change!");
        console.log(quill.getText()); // Get text only
        console.log(quill.getContents()); // Get delta contents
        console.log(quill.root.innerHTML); // Get innerHTML using quill
        console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef

        onChange(quill.root.innerHTML);
      });
    }
  }, [quill]);
  return (
    <div className={" " + className}>
      <label
        htmlFor="password"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div ref={quillRef} />
    </div>
  );
};

export default RichTextEditor;
