import { ReactNode } from 'react'
import { Node } from 'typescript'
import Header from '../components/header/Header'

const PageLayout = ({ children, label,leftComp }: { children: ReactNode, label: string,leftComp?:ReactNode }) => {
    return (
        <div className='h-full w-full' >
            <Header label={label} leftComp={leftComp}  />
            <div className='h-full w-full p-2 overflow-auto' >
                {children}
            </div>
        </div>
    )
}

export default PageLayout